<template>
  <div class="innerSearch im-inner-search-view">
    <MainHeader :title="headerTitle"></MainHeader>
    <div class="contentBlock">
      <div class="inputBlock">
        <div class="inner">
          <i
            class="iconfont_Me icon-left iconStyle"
            @click.stop="$emit('close')"
          ></i>
          <div class="inputBox">
            <i class="iconfont_Me icon-search searchStyle"></i>
            <el-input
              v-model="searchKey"
              @focus="openHistory"
              @keyup.enter.native="search"
            ></el-input>
          </div>
        </div>
      </div>
      <SearchList
        :searchType="'inner'"
        ref="searchList"
        :searchParams="searchParams"
        @handlerSearch="handlerInnerSearch"
        @setCatchSearchWord="setCatchSearchWord"
      ></SearchList>
    </div>
  </div>
</template>
<script>
import MainHeader from "../components/mainHeader";
import SearchList from "../search-list";
import { allCollect } from "@/api/newVersion/discussionGroupClassification";
export default {
  inject: ["control"],
  components: {
    MainHeader,
    SearchList,
  },
  data() {
    return {
      searchKey: "",
      searchData: {
        //好友
        msgs: [],
        //群组
        imgs: [],
        //聊天记录
        videos: [],
        //附件
        files: [],
        // 简历
        resumes: [],
      },
      searchTotal: 0,
      searchParams: {
        openSearch: false,
        listData: this.searchData,
        total: this.searchTotal,
      },
      headerTitle: "",
    };
  },
  watch: {
    searchKey: {
      async handler(val, old) {
        if (val == "" && val != old) {
          await this.$nextTick();
          this.search();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.searchParams = {
      openSearch: true,
      listData: this.searchData,
      total: this.searchTotal,
    };
    this.headerTitle = this.control.currentContact.displayName;
    try {
      let collects = await allCollect();
      this.$store.commit("setAllCollects", collects.data.data || []);
    } catch (error) {}
  },
  methods: {
    // 开始搜索
    search() {
      this.$store.commit("setShowSearchHistory", false);
      this.$nextTick(() => {
        this.$refs.searchList.handlerEmitRequestSearch();
      });
    },
    // 打开历史记录列表
    openHistory() {
      if (this.searchKey == "") {
        this.$store.commit("setShowSearchHistory", true);
      }
    },
    // 会话内部搜索
    handlerInnerSearch(event) {
      const next = {
        success: (data, total) => {
          this.searchData[event.activeTab + "s"] = data;
          this.searchTotal = total;
          if (event.successCallback) {
            event.successCallback();
          }
        },
        error: (message = "error") => {
          if (event.errorCallback) {
            event.errorCallback(message);
          }
        },
        ...event,
      };
      if (this.control.currentContact.isGroup) {
        next.from = `G${this.control.currentContact.id}`;
      }
      if (event.selectedKeywords) {
        this.searchKey = event.selectedKeywords;
        delete event.selectedKeywords;
      }
      this.control.$emit(
        `request-search-${event.activeTab}`,
        this.searchKey,
        "",
        next,
        event.subType
      );
    },
    // 设置跳转关键字
    setCatchSearchWord() {
      localStorage.setItem("catchWord", this.searchValue);
    },
  },
};
</script>

<style lang="stylus" scoped>
.innerSearch
  flex: 1;
  min-width: 0;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .contentBlock
    width: 100%;
    flex: 1;
    min-height: 0;
    overflow: hidden;
    .inputBlock
      background: #EEEEEE;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .inner
        height: 26px;
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .iconStyle
          font-size: 20px;
          color #292D32;
          cursor pointer;
        .inputBox
          width: 295px;
          height: 26px;
          border-radius: 13px;
          background: #FFFFFF;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 10px;
          .searchStyle
            font-size: 14px;
</style>
<style lang="stylus">
.im-inner-search-view
  .contentBlock
    .inputBlock
      .inputBox
        .el-input
          height: 26px;
          .el-input__inner
            height: 100%;
            padding: 0 15px 0 5px;
            box-sizing: border-box;
            border-radius: 13px;
            border: none;
            font-size: 12px;
</style>
