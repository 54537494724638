<template>
  <div class="viewHeader">
    <div class="headerTitle">
      {{ title == "" ? $t("contacts") : title }}
    </div>
    <span
      class="imelink-miniscreen"
      v-show="this.$store.state.imStore.IMWindowSize != 'mini'"
      @click="changeScreen('mini')"
    >
      <SizeButton :buttonType="'minimize'"></SizeButton>
    </span>
    <span
      class="imelink-fullscreen"
      v-show="this.$store.state.imStore.IMWindowSize == 'medium'"
      @click="changeScreen('full')"
    >
      <SizeButton></SizeButton>
    </span>
    <span
      class="imelink-fullscreen"
      v-show="this.$store.state.imStore.IMWindowSize == 'full'"
      @click="changeScreen('medium')"
    >
      <SizeButton :buttonType="'full'"></SizeButton>
    </span>
  </div>
</template>
<script>
import SizeButton from "@/newVersion/components/im_size_button";
export default {
  inject: ["control"],
  components: {
    SizeButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    changeScreen(type) {
      this.control.changeScreen(type);
    },
  },
};
</script>
<style lang="stylus" scoped>
.viewHeader
  width: 100%;
  overflow: hidden;
  line-height: 54px !important;
  height: 54px !important;
  background: #f9f9f9;
  border-right: 1px solid #eee;
  position: relative;
  text-align: center;
  flex-shrink: 0;
  z-index: 5;
  .headerTitle
    color: #1A1A1A;
    margin: 0 auto;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
  .imelink-miniscreen, .imelink-fullscreen
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    line-height: 24px;
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
    color: #1a1a1a !important;
    &:hover
      background: rgba(0, 0, 0, 0.1);
  .imelink-miniscreen
    right: 30px;
</style>
