var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "viewHeader" }, [
    _c("div", { staticClass: "headerTitle" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.title == "" ? _vm.$t("contacts") : _vm.title) +
          "\n  "
      )
    ]),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: this.$store.state.imStore.IMWindowSize != "mini",
            expression: "this.$store.state.imStore.IMWindowSize != 'mini'"
          }
        ],
        staticClass: "imelink-miniscreen",
        on: {
          click: function($event) {
            return _vm.changeScreen("mini")
          }
        }
      },
      [_c("SizeButton", { attrs: { buttonType: "minimize" } })],
      1
    ),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: this.$store.state.imStore.IMWindowSize == "medium",
            expression: "this.$store.state.imStore.IMWindowSize == 'medium'"
          }
        ],
        staticClass: "imelink-fullscreen",
        on: {
          click: function($event) {
            return _vm.changeScreen("full")
          }
        }
      },
      [_c("SizeButton")],
      1
    ),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: this.$store.state.imStore.IMWindowSize == "full",
            expression: "this.$store.state.imStore.IMWindowSize == 'full'"
          }
        ],
        staticClass: "imelink-fullscreen",
        on: {
          click: function($event) {
            return _vm.changeScreen("medium")
          }
        }
      },
      [_c("SizeButton", { attrs: { buttonType: "full" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }